
// COMPONENTS
//import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

// APP
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
	name: "my-projects",
	/*	components: {
		Dropdown3,
	},
*/

	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		let userProfileTabs = [
			{
				componentName: "DashboardOverview",
				path: "/dashboard/overview",
				tabName: "Overview",
			},
			{
				componentName: "DashboardTargets",
				path: `/dashboard/target`,
				tabName: "Targets",
			},
			{
				componentName: "DashboardBudget",
				path: `/dashboard/budget`,
				tabName: "Budget",
			},
			{
				componentName: "DashboardUsers",
				path: `/dashboard/users`,
				tabName: "Users",
			},
			{
				componentName: "DashboardFiles",
				path: `/dashboard/files`,
				tabName: "Files",
			},
			{
				componentName: "DashboardActivity",
				path: `/dashboard/activity`,
				tabName: "Activity",
			},
			{
				componentName: "DashboardSetting",
				path: `/dashboard/settings`,
				tabName: "Settings",
			},
		];

		let user = ref(null);

		onMounted(async () => {
			setCurrentPageTitle("Dashboard");
			MenuComponent.reinitialization();
		});
		return {
			userProfileTabs,
			user,
		};
	},
});
